import AES from "crypto-js/aes";
import encUTF8 from "crypto-js/enc-utf8";
import sha256 from "crypto-js/sha256";
import unknownErrorToError from "../../lib/unknownErrorToError";
import logger from "./logger";

export type JSONType<T> = T extends { toJSON(): infer U }
  ? U
  : T extends object
    ? {
        [k in keyof T]: JSONType<T[k]>;
      }
    : T;

type StorageType = "local" | "session";

export const GlobalAccountID = "global";
export const NonEncryptingSecret = "no-encrypt";

enum StoreMethodName {
  setItem,
  getItem,
  removeItem,
}

type Collection = {
  [k: string]: number;
};
function addKeysToCollection({
  keys,
  collectionKey,
  store,
  maxSize,
}: {
  keys: string[];
  collectionKey: string;
  store: StorageType;
  maxSize: number;
}) {
  const collection =
    getItem<Collection>({
      key: `storage/collection/${collectionKey}`,
      store,
      secret: NonEncryptingSecret,
      accountID: GlobalAccountID,
    }) ?? {};

  logger.debug(
    `storage: addKeysToCollection([${keys.join(",")}], ${collectionKey})`,
    collection
  );

  keys.forEach((key) => {
    collection[key] = Date.now();
  });

  const entries = Object.entries(collection);
  if (entries.length > maxSize) {
    const purgedKeys = [];
    // sort from highest to lowest, so that we can pop the oldest item from
    // the stack
    entries.sort(
      ([keyA, timestampA], [keyB, timestampB]) => timestampB - timestampA
    );

    const storage = getStorage(store);
    while (entries.length > maxSize) {
      const entry = entries.pop();
      if (!entry) {
        break;
      }
      const [key] = entry;
      storage.removeItem(key);
      purgedKeys.push(key);
      delete collection[key];
    }

    logger.debug(
      `storage: purged keys from collection, as maxSize of ${maxSize} was exceeded`,
      purgedKeys
    );
  }

  setItem<Collection>({
    key: `storage/collection/${collectionKey}`,
    store,
    secret: NonEncryptingSecret,
    accountID: GlobalAccountID,
    value: collection,
  });
}

function removeKeysFromCollection({
  keys,
  collectionKey,
  store,
}: {
  keys: string[];
  collectionKey: string;
  store: StorageType;
}) {
  const collection =
    getItem<Collection>({
      key: `storage/collection/${collectionKey}`,
      store,
      secret: NonEncryptingSecret,
      accountID: GlobalAccountID,
    }) ?? {};

  keys.forEach((key) => {
    delete collection[key];
  });

  setItem<Collection>({
    key: `storage/collection/${collectionKey}`,
    store,
    secret: NonEncryptingSecret,
    accountID: GlobalAccountID,
    value: collection,
  });
}

function optionsToKey({
  key,
  secret,
  accountID,
}: {
  accountID?: string;
  key: string;
  secret: string;
}): string {
  if (secret !== NonEncryptingSecret && !accountID) {
    throw new Error("trying to access encrypted data without account ID");
  }

  return secret === NonEncryptingSecret
    ? `spruce/${accountID || GlobalAccountID}/${key}`
    : sha256(`spruce/${accountID}/${key}/${secret}`).toString();
}

class FallbackStorage {
  data = new Map<string, string>();
  getItem(key: string) {
    return this.data.get(key) ?? null;
  }
  setItem(key: string, value: string) {
    this.data.set(key, value);
  }
  removeItem(key: string) {
    this.data.delete(key);
  }
}

const fallbackSessionStorage = new FallbackStorage();
const fallbackLocalStorage = new FallbackStorage();

const storageTestKey = "spruce/test-storage";
function getStorage(store: StorageType) {
  const storage =
    store === "local" ? window.localStorage : window.sessionStorage;
  const backupStorage =
    store === "local" ? fallbackLocalStorage : fallbackSessionStorage;

  try {
    storage.setItem(storageTestKey, storageTestKey);
    storage.removeItem(storageTestKey);
    return storage;
  } catch (e) {
    logger.error(unknownErrorToError(e));
  }

  return backupStorage;
}

const listeners = new Map<
  string,
  { secret: string; callback: (a: any) => void }[]
>();

type CallStorageOptions<T> =
  | {
      store: StorageType;
      method: StoreMethodName.setItem;
      accountID?: string;
      key: string;
      secret: string;
      value?: T;
      collectionKey: string;
      collectionMaxSize: number;
    }
  | {
      store: StorageType;
      method: StoreMethodName.removeItem;
      accountID?: string;
      key: string;
      secret: string;
      value?: T;
      collectionKey: string;
      collectionMaxSize?: undefined;
    }
  | {
      store: StorageType;
      method: StoreMethodName;
      accountID?: string;
      key: string;
      secret: string;
      value?: T;
      collectionKey?: undefined;
      collectionMaxSize?: undefined;
    };

function callStorage<T>({
  store = "local",
  method,
  accountID,
  key,
  value,
  secret,
  collectionKey,
  collectionMaxSize,
}: CallStorageOptions<T>): JSONType<T> | undefined {
  const storage = getStorage(store);

  let encryptedKey;
  try {
    encryptedKey = optionsToKey({ key, secret, accountID });
  } catch (e) {
    logger.error(unknownErrorToError(e));
    return;
  }

  const encryptedValue =
    typeof value !== "undefined"
      ? secret === NonEncryptingSecret || window.REACT_APP_ENVIRONMENT === "dev"
        ? JSON.stringify(value)
        : AES.encrypt(JSON.stringify(value), secret).toString()
      : null;

  try {
    switch (method) {
      case StoreMethodName.setItem:
        if (encryptedValue === null) {
          throw new Error("Missing value for localStorage.setItem() call");
        }
        storage.setItem(encryptedKey, encryptedValue);
        if (collectionKey) {
          if (!collectionMaxSize) {
            logger.error(
              new Error(
                "storage: used `collectionKey` without `collectionMaxSize`, using a default of 10 to fail gracefully"
              )
            );
          }
          addKeysToCollection({
            keys: [encryptedKey],
            collectionKey,
            maxSize: collectionMaxSize ?? 10,
            store,
          });
        }
        listeners.get(encryptedKey)?.forEach(({ callback }) => callback(value));
        return;
      case StoreMethodName.getItem:
        const result = storage.getItem(encryptedKey);
        if (!result) {
          return;
        }
        if (
          secret !== NonEncryptingSecret &&
          window.REACT_APP_ENVIRONMENT !== "dev"
        ) {
          return JSON.parse(AES.decrypt(result, secret).toString(encUTF8));
        } else {
          return JSON.parse(result);
        }
      case StoreMethodName.removeItem:
        storage.removeItem(encryptedKey);
        listeners
          .get(encryptedKey)
          ?.forEach(({ callback }) => callback(undefined));
        if (collectionKey) {
          removeKeysFromCollection({
            keys: [encryptedKey],
            collectionKey,
            store,
          });
        }
        return;
    }
  } catch (e) {
    logger.error(unknownErrorToError(e));
  }

  return;
}

type SetItemOptions<T> =
  | {
      store?: StorageType;
      accountID?: string;
      secret: string;
      key: string;
      value: T;
      collectionKey: string;
      collectionMaxSize: number;
    }
  | {
      store?: StorageType;
      accountID?: string;
      secret: string;
      key: string;
      value: T;
      collectionKey?: undefined;
      collectionMaxSize?: undefined;
    };

export function setItem<T>({
  store = "local",
  accountID,
  secret,
  key,
  value,
  collectionKey,
  collectionMaxSize,
}: SetItemOptions<T>) {
  return callStorage<T>(
    collectionKey && collectionMaxSize
      ? {
          store,
          method: StoreMethodName.setItem,
          accountID,
          key,
          value,
          secret,
          collectionKey,
          collectionMaxSize,
        }
      : {
          store,
          method: StoreMethodName.setItem,
          accountID,
          key,
          value,
          secret,
        }
  );
}

export function getItem<T>({
  store = "local",
  accountID,
  secret,
  key,
}: {
  store?: StorageType;
  accountID?: string;
  key: string;
  secret: string;
}) {
  return callStorage<T>({
    store,
    method: StoreMethodName.getItem,
    accountID,
    key,
    secret,
  });
}

export function removeItem({
  store = "local",
  accountID,
  secret,
  key,
  collectionKey,
}: {
  store?: StorageType;
  accountID?: string;
  key: string;
  secret: string;
  collectionKey?: string;
}) {
  return callStorage({
    store,
    method: StoreMethodName.removeItem,
    accountID,
    key,
    secret,
    collectionKey,
  });
}

window.addEventListener("storage", ({ key, newValue }: StorageEvent) => {
  if (!key) {
    return;
  }

  const keyListeners = listeners.get(key);
  keyListeners?.forEach(({ secret, callback }) => {
    if (secret !== NonEncryptingSecret) {
      if (typeof newValue !== "string") {
        logger.error(
          new Error(
            "failed to notify listeners, newValue should be encrypted, but it is not a string"
          )
        );
        return;
      }
      const decryptedValue = AES.decrypt(newValue, secret).toString(encUTF8);

      let parsedValue: any = undefined;
      try {
        parsedValue = JSON.parse(decryptedValue);
      } catch (e) {
        logger.error(unknownErrorToError(e));
        return;
      }

      if (typeof parsedValue === "undefined") {
        return;
      }

      return callback(parsedValue);
    } else {
      return newValue ? callback(JSON.parse(newValue)) : callback(null);
    }
  });
});

export function listen<T>(
  {
    key,
    secret,
    accountID,
  }: {
    key: string;
    secret: string;
    accountID?: string;
  },
  callback: (value: JSONType<T> | null) => void
) {
  let encryptedKey: string;
  try {
    encryptedKey = optionsToKey({ key, secret, accountID });
  } catch (e) {
    logger.error(unknownErrorToError(e));
    return;
  }

  const keyListeners = listeners.get(encryptedKey) ?? [];
  const listener = { secret, callback };
  keyListeners.push(listener);
  listeners.set(encryptedKey, keyListeners);
  return () => {
    const keyListeners = listeners.get(encryptedKey);
    if (!keyListeners) {
      return;
    }

    const index = keyListeners.indexOf(listener);
    if (index === -1) {
      return;
    }

    keyListeners.splice(index, 1);
    listeners.set(encryptedKey, keyListeners);
  };
}

const storage = { setItem, getItem, removeItem, listen };
export default storage;
