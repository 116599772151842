import { LogLevel } from "../../hooks/lib/logger";
import storage, {
  GlobalAccountID,
  NonEncryptingSecret,
} from "../../hooks/lib/storage";

export default function determineLogLevelsAccordingToStorage() {
  const remoteLoggingEnabledUntilISOString = storage.getItem<LogLevel>({
    accountID: GlobalAccountID,
    secret: NonEncryptingSecret,
    key: "RemoteLoggingEnabledUntil",
    store: "local",
  });

  let remoteLogLevel = null;
  if (remoteLoggingEnabledUntilISOString) {
    const remoteLoggingEnabledUntil = new Date(
      remoteLoggingEnabledUntilISOString
    );
    const now = new Date();
    if (
      // when the date fails to parse, valueOf() returns NaN
      !isNaN(remoteLoggingEnabledUntil.valueOf()) &&
      now < remoteLoggingEnabledUntil
    ) {
      remoteLogLevel = LogLevel.Debug;
    }
  }

  const persistedLogLevel = storage.getItem<LogLevel>({
    accountID: GlobalAccountID,
    secret: NonEncryptingSecret,
    key: "Console",
    store: "local",
  });

  if (typeof persistedLogLevel === "undefined") {
    storage.setItem({
      accountID: GlobalAccountID,
      secret: NonEncryptingSecret,
      key: "Console",
      store: "local",
      value: LogLevel.Error,
    });
  }

  const temporaryLogLevel = storage.getItem<LogLevel>({
    accountID: GlobalAccountID,
    secret: NonEncryptingSecret,
    key: "Console",
    store: "session",
  });

  const localLogLevel =
    temporaryLogLevel ?? persistedLogLevel ?? LogLevel.Error;

  return { localLogLevel, remoteLogLevel };
}
