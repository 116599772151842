import { useEffect, useState } from "react";

import isWindowVisible from "../lib/isWindowVisible";

export default function useIsWindowVisible() {
  const [visible, setVisible] = useState(isWindowVisible());

  useEffect(() => {
    const handleVisibilityChange = () => setVisible(isWindowVisible());
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [setVisible]);

  return visible;
}
