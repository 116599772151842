import logger from "../hooks/lib/logger";

function popTopFrameOffErrorStack(error: Error) {
  // NOTE: feel free to remove this log once we validate that this change to pop
  // the latest item off the stack is working as expected
  logger.log("popping one frame off the stack for error: ", error);

  if (error.stack) {
    error.stack = error.stack.split("\n").slice(1).join("\n");
  }
  return error;
}

export default function unknownErrorToError(err: unknown) {
  if (err instanceof Error) {
    return err;
  } else if (typeof err === "object" && err !== null) {
    // we pop one item off the stack so that this file doesn't show up in the stack
    return popTopFrameOffErrorStack(new Error(JSON.stringify(err)));
  } else if (typeof err === "string") {
    // we pop one item off the stack so that this file doesn't show up in the stack
    return popTopFrameOffErrorStack(new Error(err));
  } else {
    let errString = "";
    try {
      errString = JSON.stringify(err);
    } catch {}
    // we pop one item off the stack so that this file doesn't show up in the stack
    return popTopFrameOffErrorStack(new Error(`unknown error: ${errString}`));
  }
}
