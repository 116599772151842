import { makeVar, useReactiveVar } from "@apollo/client";
import throttle from "lodash.throttle";
import useIsWindowVisible from "./use-is-window-visible";

const lastActivityTimestampVar = makeVar(Date.now());
const isUserIdleVar = makeVar(false);

// We listen for user activity events and update the lastActivityTimestampVar to the current time
// at most every 500 ms.
const handleActivity = throttle(() => {
  // Lets ignore activity if the document does not have focus.
  if (document.hasFocus()) {
    lastActivityTimestampVar(Date.now());
  }
}, 500);

document.addEventListener("mousemove", handleActivity);
document.addEventListener("keydown", handleActivity);
document.addEventListener("touchstart", handleActivity);

// Every second, we check to see if the user has been inactive for more than 5 minutes,
// and if so, we update the isUserIdleVar.
window.setInterval(() => {
  const inactivityThresholdInSeconds =
    window.isReflectTest && window.s_isTestingIsWindowInactiveFunctionality
      ? 5
      : 60 * 5;
  const secondsSinceLastActivity =
    (Date.now() - lastActivityTimestampVar()) / 1000;
  const newIsIdle = secondsSinceLastActivity > inactivityThresholdInSeconds;

  if (isUserIdleVar() !== newIsIdle) {
    isUserIdleVar(newIsIdle);
  }
}, 1000);

export default function useIsWindowInactive() {
  const isUserIdle = useReactiveVar(isUserIdleVar);
  const isVisible = useIsWindowVisible();

  return isUserIdle || !isVisible;
}

// This allows us to test the functionality of the isWindowInactive logic
// https://app.reflect.run/tests/129934/definition
if (window.isReflectTest) {
  window.s_isWindowInactive = isUserIdleVar;
}

export { isUserIdleVar as isWindowInactive };
