import { useCallback } from "react";
import logger from "./lib/logger";

export type TranslateI18N = (
  key: string,
  mapping?: Record<string, any>
) => string;

// Modeled after https://www.i18next.com/overview/api#getfixedt
export function getFixedT(language?: string, namespace?: string) {
  return (key: string, mapping?: Record<string, any>) => {
    if (mapping) {
      let interpolatedKey = key;
      Object.keys(mapping).forEach((k) => {
        let v = mapping[k];
        let prevInterpolatedKey = "";
        while (prevInterpolatedKey !== interpolatedKey) {
          prevInterpolatedKey = interpolatedKey;
          interpolatedKey = interpolatedKey.replace(`{{${k}}}`, `${v}`);
        }
        if (prevInterpolatedKey === "") {
          logger.error(
            new Error(
              `key ${key} missing in translation where mapping is ${JSON.stringify(
                mapping
              )}`
            )
          );
        }
      });
      return interpolatedKey;
    } else {
      return key;
    }
  };
}

// Modeled after useTranslation() from the i18n library (https://react.i18next.com/latest/usetranslation-hook), so that we might replace our implementation with its implementation one day
export default function useTranslation(namespace?: string) {
  return {
    // kevin: I don't know how to fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t: useCallback(getFixedT(), []),
  };
}
