import { getFixedT } from "../hooks/use-translation";

const t = getFixedT();

// intended to improve common error messages to be more user-presentable
export function rewriteErrorMessage(errorMessage: string): string {
  // NOT LOCALIZABLE
  if (errorMessage === "TypeError: Failed to fetch") {
    return t(
      "We were unable to connect to the server. Please check your internet connection."
    );
  }
  return errorMessage;
}
