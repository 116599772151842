import { Logger as TwilioLogger } from "twilio-video";

import logger, { LogLevel } from "../hooks/lib/logger";

export default function setTwilioVideoLogLevelBasedOnOurLoggerLevel() {
  const twilioLogger = TwilioLogger.getLogger("twilio-video");

  switch (logger.logLevel) {
    case LogLevel.Debug:
      twilioLogger.setLevel("debug");
      break;
    case LogLevel.Log:
      twilioLogger.setLevel("info");
      break;
    case LogLevel.Warn:
      twilioLogger.setLevel("warn");
      break;
    case LogLevel.Error:
      twilioLogger.setLevel("error");
      break;
  }
}
