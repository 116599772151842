import emoji from "./emoji";

const hashString = (string: string): number => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0;
  }
  return Math.abs(hash);
};

export default function toEmoji(string: string): string {
  const hash = hashString(string);
  return `${emoji[hash % emoji.length]}`;
}
