import { AnalyticsEventPropertyPairInput } from "../../generated/types";
import safePutAnalytics from "../safePutAnalytics";
import remoteLoggingEnabledVar from "./remoteLoggingEnabledVar";

let events: {
  eventName: string;
  eventTime: any;
  properties: AnalyticsEventPropertyPairInput[];
}[] = [];
let timeout: number | null = null;

const maxDelayInSeconds = 10;
const maxNumEvents = 50;

export default function logRemotely({
  method,
  args,
}: {
  method: string;
  args: any[];
}) {
  if (!remoteLoggingEnabledVar()) {
    return;
  }

  const internalEvent = {
    eventName: `log.${method}`,
    eventTime: Date.now() / 1000,
    properties: args.flatMap((arg, index) => {
      if (typeof arg === "string") {
        return [
          {
            key: `${index}`,
            stringValue: { value: arg },
          },
        ];
      }
      let value = "[couldn't JSON.stringify argument]";
      try {
        // this fails sometimes due to circular references
        value = JSON.stringify(arg);
      } catch {
        // do nothing
      }
      if (!value || value === "{}") {
        return [];
      }
      return [
        {
          key: `${index}`,
          stringValue: { value },
        },
      ];
    }),
  };

  events.push(internalEvent);

  if (timeout !== null) {
    clearTimeout(timeout);
  }

  // create a memory efficient shallow copy of events so that it doesn't change
  // while we're waiting for the timeout to fire
  // this is apparently effectively compiled to `events.slice()`,
  // which performs a shallow copy
  const eventsShallowCopy = [...events];

  timeout = window.setTimeout(
    () => {
      // we use this `safePutAnalytics` since it has no calls to logger.log (which would cause a loop)
      // and we do it asynchronously defensively in case somehow a logger.log call is added to this function
      // so that at least it's not a locking loop
      safePutAnalytics({
        mutationPrefix: "RemoteLogging",
        events: eventsShallowCopy,
      });
      timeout = null;
      events = [];
    },
    // again, we specify timeout 0 instead of doing this synchronously
    // because we want to defensively avoid a loop in the event that `safePutAnalytics`
    // somehow causes a loop
    events.length >= maxNumEvents ? 0 : maxDelayInSeconds * 1000
  );
}
