import isEnvironment from "./isEnvironment";

let url: string = "https://admin.corp.sprucehealth.com";

// NOTE(jon): at the time of this writing, REACT_APP_ADMIN_PANEL_BASE_URL
//  is only defined when running against the monolith and dev
if (window.REACT_APP_ADMIN_PANEL_BASE_URL) {
  url = window.REACT_APP_ADMIN_PANEL_BASE_URL;
} else if (isEnvironment("staging")) {
  url = "https://staging-admin.corp.sprucehealth.com";
} else if (isEnvironment("dev")) {
  url = "https://dev-admin.corp.sprucehealth.com";
}

export const adminBaseURL = url;
